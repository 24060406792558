/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { RouteComponentProps } from 'react-router-dom';

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

export default <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P> => {
  const trackPage = (page: string) => {
    ReactGA.send({ hitType: 'pageview', page });
  };

  // Initializing with `debug` option:

  return (props: P) => {
    React.useEffect(() => {
      trackPage(props.location.pathname);

      if (hotjar.initialized()) {
        hotjar.stateChange(props.location.pathname);
      }
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
