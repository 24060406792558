import React from 'react';
import ReactDOM from 'react-dom';
import { hotjar } from 'react-hotjar';
import App from './App';

// Sentry.init({
//   dsn: 'https://c2698727c1ad4709834a22b9cd8fb8e4@sentry.intmedapis.com/3',
//   integrations: [new BrowserTracing({ tracingOrigins: ['*'] })],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment: process.env.REACT_APP_ENV || 'development',
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
// });

hotjar.initialize({ id: 5217649, sv: 6 });

ReactDOM.render(<App />, document.getElementById('root'));
